import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EOrderItemType, IItem } from "../interfaces/IOrder";
import classes from "../modules/orderPage.module.css";
import ProductCard from "./ProductCard";
import ProductsTable from "./ProductsTable";

interface IProductsSectionProps {
  products: IItem[];
  total: number;
  currency: string;
  restToMinimumOrder?: number;
  minimumOrder?: number;
}

export default function ProductsSection({
  products,
  total,
  currency,
  restToMinimumOrder,
  minimumOrder,
}: IProductsSectionProps) {
  const { i18n, t } = useTranslation();
  const locale = i18n.language;

  const newProducts = useCallback(() => {
    const temp = [...products];
    if (restToMinimumOrder && minimumOrder) {
      temp.push({
        length: 0,
        name: t("remainingRest"),
        quantity: 1,
        totalPriceItem: restToMinimumOrder || 0,
        type: EOrderItemType.CLOTHES,
        width: 0,
        pricePerUnit: restToMinimumOrder || 0,
        id: "minimum-order",
      });
    }
    return temp;
  }, [products, restToMinimumOrder, minimumOrder]);

  const newTotal = useCallback(() => {
    if (restToMinimumOrder && Number(restToMinimumOrder) > 0) {
      return minimumOrder || 0;
    }
    return total || 0;
  }, [total, minimumOrder, restToMinimumOrder]);

  return (
    <div style={{ width: "100%" }}>
      <h4
        className="h4"
        style={{
          marginBottom: "var(--md)",
        }}
      >
        {t("v2.OrderPage.productsSection.title")}
      </h4>
      <div className={classes.productsGrid}>
        {newProducts()?.map((item, i) => (
          <ProductCard
            currency={currency}
            index={i + 1}
            locale={locale}
            product={item}
            key={i}
          />
        ))}
      </div>
      <div className={classes.productsTableContainer}>
        <ProductsTable currency={currency} products={newProducts()} />
      </div>
      <p className="bt" style={{ textAlign: "right", marginTop: "var(--md)" }}>
        {t("v2.OrderPage.productsSection.total")}:{" "}
        {newTotal().toLocaleString(locale, {
          style: "currency",
          currency,
        })}
      </p>
    </div>
  );
}
